<template>
    <admin-dashboard-layout>
        <v-data-table
            :search="search"
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="creators"
            :items-per-page="quantityItems"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:top>
                <v-text-field
                    v-model="search"
                    label="Search by name"
                    class="mx-4"
                ></v-text-field>
            </template>
            <template v-slot:item.verify_at="{ item }">
                {{ moment(item.verify_at).format('LLL') }}
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ moment(item.created_at).format('LLL') }}
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
    import Creator from '../../../models/admin/Creator';
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import {ADMIN_QUANTITY_ITEMS_LIST} from "../../../configs/constants";
    /* eslint-disable */
    export default {
        name: "creators-index",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                loading: false,
                quantityItems: ADMIN_QUANTITY_ITEMS_LIST,
                creators: [],
                search: '',
                headers: [
                    {
                        text: '# ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    { text: 'Name', value: 'user.name' },
                    { text: 'Verified at', value: 'verify_at' },
                    { text: 'Created at', value: 'created_at' },
                ],
            }
        },
        methods: {
            handleClickRow(creator) {
                this.$router.push({name: 'admin.creators.show', params: {id: creator.id}})
            }
        },
        async mounted() {
            this.loading = true
            this.creators = await Creator.include('user').get()
            this.loading = false
        },
    }
</script>

<style scoped>

</style>
